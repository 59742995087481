import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminPanel from './Components/AdminPanel';
import LoginPage from './Components/LoginPage'; // Your login component
import ProtectedRoute from './Components/ProtectedRoute';

const App = () => {
    // Assuming you have some state or context to check if the user is authenticated and authorized
    const googleResponse = JSON.parse(localStorage.getItem('googleResponse'));
    
    // Check if the user is authenticated. If the googleResponse is null, the user is not authenticated
    const isAuthenticated = (googleResponse && googleResponse.accessToken) !== null;

    const oauthToken = googleResponse ? googleResponse.credential : null;

    const isAdmin = true;

    const adminApiKey = process.env.REACT_APP_API_KEY; // Replace with actual API key
    const apiBaseUrl = process.env.REACT_APP_API_URL;

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin" element={
                    isAuthenticated && isAdmin 
                    ? <AdminPanel apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} /> 
                    : <Navigate replace to="/login" />
                } />
                <Route path="/" element={<Navigate replace to="/login" />} />
            </Routes>
        </Router>
    );
};

export default App;
