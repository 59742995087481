import React, { useState, useEffect } from 'react';

const ManualReviewToggle = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [isManualReviewEnabled, setIsManualReviewEnabled] = useState(false);

    useEffect(() => {
        fetch(`${apiBaseUrl}/get_manual_review`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}`
        })
        .then(response => response.json())
        .then(data => setIsManualReviewEnabled(data.manual_review))
        .catch(error => console.error('Error fetching manual review mode:', error));
    }, [apiBaseUrl, adminApiKey]);

    const handleToggleChange = () => {
        const newManualReviewState = !isManualReviewEnabled;
        setIsManualReviewEnabled(newManualReviewState);
        updateManualReviewMode(newManualReviewState);
    };

    const updateManualReviewMode = async (manualReview) => {
        try {
            const response = await fetch(`${apiBaseUrl}/set_manual_review`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}&manual_review=${manualReview}`
            });
            const data = await response.json();
            if (data.status !== 'success') {
                // Handle unsuccessful update
                console.error('Failed to update manual review mode');
            }
        } catch (error) {
            console.error('Error updating manual review mode:', error);
        }
    };

    // Refresh the button state every so often
    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${apiBaseUrl}/get_manual_review`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`

                },
                body: `api_key=${adminApiKey}`
            })
            .then(response => response.json())
            .then(data => setIsManualReviewEnabled(data.manual_review))
            .catch(error => console.error('Error fetching manual review mode:', error));
        }, 10000);
        return () => clearInterval(interval);
    }
    , []);


    return (
        <div>
            <label>
                Manual Review Mode:
                <input 
                    type="checkbox" 
                    checked={isManualReviewEnabled} 
                    onChange={handleToggleChange} 
                />
            </label>
        </div>
    );
};

export default ManualReviewToggle;
