import React, { useState, useEffect } from 'react';

const StatsView = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [stats, setStats] = useState({
        dailyOrders: 0,
        dailyRevenue: 0,
        dailyProfit: 0,
        monthlyOrders: 0,
        monthlyRevenue: 0,
        monthlyProfit: 0,
        dailyLandings: 0,
        dailyStarts: 0,
        dailyQuotes: 0,
    });

    const fetchStat = async (endpoint) => {
        try {
            const response = await fetch(`${apiBaseUrl}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}`
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Error fetching ${endpoint}:`, error);
            return null;
        }
    };

    useEffect(() => {
        const loadStats = async () => {
            const dailyOrdersData = await fetchStat('get_daily_orders');
            const dailyRevenueData = await fetchStat('get_daily_revenue');
            const dailyProfitData = await fetchStat('get_daily_profit');
            const monthlyOrdersData = await fetchStat('get_monthly_orders');
            const monthlyRevenueData = await fetchStat('get_monthly_revenue');
            const monthlyProfitData = await fetchStat('get_monthly_profit');
            const dailyLandingsData = await fetchStat('get_daily_landings');
            const dailyStartsData = await fetchStat('get_daily_starts');
            const dailyQuotesData = await fetchStat('get_daily_quotes');
            
            // Count the number of orders in the daily orders list of orders
            const dailyOrders = dailyOrdersData?.daily_orders?.order_cost || 0;
            const monthly_orders = monthlyOrdersData?.monthly_orders?.order_cost || 0;

            console.log(dailyOrders);
            console.log(dailyRevenueData);
            console.log(dailyProfitData);
            console.log(monthly_orders);
            console.log(monthlyRevenueData);
            console.log(monthlyProfitData);
            console.log(dailyLandingsData);
            console.log(dailyStartsData);
            console.log(dailyQuotesData);

            setStats({
                dailyOrders: dailyOrders,
                dailyRevenue: dailyRevenueData?.daily_revenue || 0,
                dailyProfit: dailyProfitData?.daily_profit || 0,
                monthlyOrders: monthly_orders || 0,
                monthlyRevenue: monthlyRevenueData?.monthly_revenue || 0,
                monthlyProfit: monthlyProfitData?.monthly_profit || 0,
                dailyLandings: dailyLandingsData?.daily_landings || 0,
                dailyStarts: dailyStartsData?.daily_starts || 0,
                dailyQuotes: dailyQuotesData?.daily_quotes || 0,
            });
        };
        loadStats();
    }, [apiBaseUrl, adminApiKey]);

    return (
        <div>
            <h2>Statistics</h2>
            <p>Daily Orders: {stats.dailyOrders}</p>
            <p>Daily Revenue: {stats.dailyRevenue}</p>
            <p>Daily Profit: {stats.dailyProfit}</p>
            <p>Monthly Orders: {stats.monthlyOrders}</p>
            <p>Monthly Revenue: {stats.monthlyRevenue}</p>
            <p>Monthly Profit: {stats.monthlyProfit}</p>
            <p>Daily Landings: {stats.dailyLandings}</p>
            <p>Daily Starts: {stats.dailyStarts}</p>
            <p>Daily Quotes: {stats.dailyQuotes}</p>
        </div>
    );
};

export default StatsView;
