import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const isDevelopment = true;

const LoginPage = () => {
    const isDevelopment = true;
    const navigate = useNavigate();

    const responseGoogle = (response) => {
        // Stash the response in local storage
        console.log("Got oauth response")
        localStorage.setItem('googleResponse', JSON.stringify(response));
        // Route to the welcome page
        navigate('/admin');
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
            <GoogleLogin
                onSuccess={(credentialResponse) => responseGoogle(credentialResponse)}
                onFailure={() => console.log("Login failed")}
            />
        </GoogleOAuthProvider>
    );
};

export default LoginPage;
