import React, { useState, useEffect, fetchStat } from 'react';

const PriceManagement = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [floor, setFloor] = useState('');
    const [ceiling, setCeiling] = useState('');
    const [budget, setBudget] = useState('');
    

    const updatePriceSetting = async (setting, value, endpoint) => {
        try {
            const response = await fetch(`${apiBaseUrl}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}&${setting}=${value}`
            });
            const data = await response.json();
            alert(`Status: ${data.status}`);
        } catch (error) {
            alert(`Error: ${error}`);
        }
    };

    const handleSetFloor = () => {
        updatePriceSetting('floor', floor, 'set_multiplier_floor');
    };

    const handleSetCeiling = () => {
        updatePriceSetting('ceiling', ceiling, 'set_multiplier_ceiling');
    };

    const handleSetBudget = () => {
        updatePriceSetting('budget', budget,'set_budget');
    };

    const [stats, setStats] = useState({
        floor: 0,
        ceiling: 0,
    });

    const fetchStat = async (endpoint) => {
        try {
            const response = await fetch(`${apiBaseUrl}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}`
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Error fetching ${endpoint}:`, error);
            return null;
        }
    }

    // Load the price settings from the server and update them periodically
    useEffect(() => {
        const loadStats = async () => {
            const floorData = await fetchStat('get_multiplier_floor');
            const ceilingData = await fetchStat('get_multiplier_ceiling');
            const budgetData = await fetchStat('get_budget');
            setBudget(budgetData?.budget || 0);
            setFloor(floorData?.floor || 0);
            setCeiling(ceilingData?.ceiling || 0);
        };
        loadStats();
        // Update text fields with the latest value
        const interval = setInterval(() => {
            loadStats();
        }, 60000);

        return () => clearInterval(interval);
    }, [apiBaseUrl, adminApiKey]);

    return (
        <div>
            <h2>Price Management</h2>
            <div>
                <label>
                    Set Floor:
                    <input type="number" value={floor} onChange={(e) => setFloor(e.target.value)} />
                </label>
                <button onClick={handleSetFloor}>Set Floor</button>
            </div>
            <div>
                <label>
                    Set Ceiling:
                    <input type="number" value={ceiling} onChange={(e) => setCeiling(e.target.value)} />
                </label>
                <button onClick={handleSetCeiling}>Set Ceiling</button>
            </div>
            <div>
                <label>
                    Set Budget:
                    <input type="number" value={budget} onChange={(e) => setBudget(e.target.value)} />
                </label>
                <button onClick={handleSetBudget}>Set Budget</button>
            </div>
        </div>
    );
};

export default PriceManagement;
