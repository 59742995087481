import React, { useState, useEffect } from 'react';

const Promos = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [promoCodes, setPromoCodes] = useState([]);
    const [newCode, setNewCode] = useState('');
    const [newDiscount, setNewDiscount] = useState('');
    const [newExpireTime, setNewExpireTime] = useState('');
    const [newOneTime, setNewOneTime] = useState(false);

    // Fetch promo codes
    useEffect(() => {
        fetch(`${apiBaseUrl}/get_promo_codes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}`
        })
        .then(response => response.json())
        .then(data => setPromoCodes(data.promo_codes))
        .catch(error => console.error('Error fetching promo codes:', error));
    }, [apiBaseUrl, adminApiKey]);

    const createPromoCode = async () => {
        // Send a POST request to /create_promo_code with the new promo code details
        // Refresh the promo codes list
        const response = await fetch(`${apiBaseUrl}/create_promo_code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}&code=${newCode}&discount=${newDiscount}&expire_time=${newExpireTime}&one_time=${newOneTime}`
        });

        // Refresh the promo codes list
        const data = await response.json();
        if (data.status === 'success') {
            console.log(data.promo_codes);
            setPromoCodes(data.promo_codes);
        }
    };

    const deletePromoCode = async (code) => {
        // Send a POST request to /delete_promo_code with the promo code to delete
        // Refresh the promo codes list
        const response = await fetch(`${apiBaseUrl}/delete_promo_code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}&code=${code}`
        });

        // Refresh the promo codes list
        const data = await response.json();
        if (data.status === 'success') {
            setPromoCodes(data.promo_codes);
        }
    };

    // Render the form for creating new promo codes
    const renderCreateForm = () => {
        return (
            <div>
                <input type="text" value={newCode} onChange={(e) => setNewCode(e.target.value)} placeholder="Code" />
                <input type="number" value={newDiscount} onChange={(e) => setNewDiscount(e.target.value)} placeholder="Discount" />
                <input type="datetime-local" value={newExpireTime} onChange={(e) => setNewExpireTime(e.target
                    .value)} placeholder="Expiration Time" />
                <label>
                    One Time Use:
                    <input type="checkbox" checked={newOneTime} onChange={(e) => setNewOneTime(e.target.checked)} />
                </label>
                <button onClick={createPromoCode}>Create Promo Code</button>
            </div>
        );
    };

    // Display the list of promo codes
    const renderPromoCodesList = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Discount</th>
                        <th>Expiration Time</th>
                        <th>One Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {promoCodes.map((promo, index) => (
                        <tr key={index}>
                            <td>{promo.code}</td>
                            <td>{promo.discount}%</td>
                            <td>{promo.expire_time ? new Date(promo.expire_time).toLocaleString() : 'Never'}</td>
                            <td>{promo.one_time == "True" ? 'Yes' : 'No'}</td>
                            <td>
                                <button onClick={() => deletePromoCode(promo.code)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div>
            <h1>Promo Codes Admin</h1>
            {renderCreateForm()}
            {renderPromoCodesList()}
        </div>
    );
}

export default Promos;