import React, { useState, useEffect } from 'react';

import '../global.css';
import ManualReviewToggle from './ManualReviewToggle';
import AcceptingOrdersToggle from './AcceptingOrdersToggle';

const OrderManagement = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [selectedServicesToRegen, setSelectedServicesToRegen] = useState({});

    useEffect(() => {
        fetch(`${apiBaseUrl}/list_pending_orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}`
        })
        .then(response => response.json())
        .then(data => setPendingOrders(data.orders))
        .catch(error => console.error('Error fetching pending orders:', error));
    }, [apiBaseUrl, adminApiKey]);

    const handleServiceCheckboxChange = (orderId, service) => {
        setSelectedServicesToRegen(prevState => ({
            ...prevState,
            [orderId]: {
                ...prevState[orderId],
                [service]: !prevState[orderId]?.[service]
            }
        }));
    };

    const handleRegenerateOrder = async (orderId) => {
        const servicesToRegen = selectedServicesToRegen[orderId];

        var servicesList = []
        if (servicesToRegen) {
            servicesList = Object.entries(servicesToRegen)
                                    .filter(([_, isSelected]) => isSelected)
                                    .map(([service, _]) => service)
                                    .join(',');
        }
        try {
            const response = await fetch(`${apiBaseUrl}/order_regenerate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `api_key=${adminApiKey}&order_id=${orderId}&services_regen=${servicesList}`
            });
            const data = await response.json();
            if (data.status === 'success') {
                alert('Order regeneration initiated successfully.');
                // Optionally refresh the order list or update the UI
            } else {
                alert('Failed to initiate order regeneration.');
            }
        } catch (error) {
            console.error('Error regenerating order:', error);
            alert('Error during regeneration request.');
        }
    };

    const renderDocument = (document) => {
        // If the document is null, return an empty div
        if (!document) {
            return <div></div>;
        }
        return (
            <div className="documents-flex-container">
                <div className="document-container">
                    <h3>{document.title} by {document.author}</h3>
                        {document.sections.map((section, index) => (
                            <div key={index}>
                                <h4>{section.title}</h4>
                                {section.content.split('\n').map((line, lineIndex) => (
                                    <p key={lineIndex}>{line}</p>
                                ))}
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    const renderOrderContent = (order) => {
        return (
            <div className="order-content-container">
                <h3>Order ID: {order.id}</h3>
                <div className="documents-flex-container">
                    {/* Render Original Document */}
                    <div className="document-container">
                        <h4>Original Document:</h4>
                        {renderDocument(JSON.parse(order.order).document)}
                    </div>
                    <div className="document-container">
                        <h4>Summary:</h4>
                        {renderDocument(order.summary)}
                    </div>
                    <div className="document-container">
                        <h4>Critique:</h4>
                        {renderDocument((order.critique))}
                    </div>
                    <div className="document-container">
                        <h4>Impressions:</h4>
                        {renderDocument((order.impressions))}
                    </div>
                    <div className="document-container">
                        <h4>Synopsis:</h4>
                        {renderDocument((order.synopsis))}
                    </div>
                    <div className="document-container">
                        <h4>Marketability:</h4>
                        {renderDocument((order.marketability))}
                    </div>
                    <div className="document-container">
                      <h4>Pitch</h4>  
                        {renderDocument((order.pitch))}
                    </div>
                    <div className="document-container">
                        <h4>Letter</h4>
                        {renderDocument((order.final))}
                    </div>
                </div>
                {/* Rest of the order content */}
            </div>
        );
    };

    const handleOrderAction = async (orderId, endpoint) => {
        try {
            const response = await fetch(`${apiBaseUrl}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}&order_id=${orderId}`
            });
            const data = await response.json();
            alert(`Status: ${data.status}`);
            // Refresh the pending orders list
        } catch (error) {
            alert(`Error: ${error}`);
        }
    };

    const renderRegenerationOptions = (order) => {
        const services = ['summary', 'critique', 'impressions', 'synopsis', 'marketability'];
        return (
            <div>
                <h4>Select Services to Regenerate:</h4>
                {services.map(service => (
                    <label key={service}>
                        <input
                            type="checkbox"
                            checked={selectedServicesToRegen[order.id]?.[service] || false}
                            onChange={() => handleServiceCheckboxChange(order.id, service)}
                        />
                        {service}
                    </label>
                ))}
                <button onClick={() => handleRegenerateOrder(order.id)}>Regenerate</button>
            </div>
        );
    };

    // Refresh the pending orders list every so often
    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${apiBaseUrl}/list_pending_orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}`
            })
            .then(response => response.json())
            .then(data => setPendingOrders(data.orders))
            .catch(error => console.error('Error fetching pending orders:', error));
        }, 10000);
        return () => clearInterval(interval);
    }
    , []);

    return (
        <div>
            <h2>Order Management</h2>
            <ManualReviewToggle apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />
            <AcceptingOrdersToggle apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />
            {pendingOrders.map(order => (
                <div key={order.id} className="order-container">
                    {renderOrderContent(order)}
                    {renderRegenerationOptions(order)}
                    <button onClick={() => handleOrderAction(order.id, 'approve_order')}>Approve</button>
                </div>
            ))}
        </div>
    );
};

export default OrderManagement;
