import React, { useState, useEffect } from 'react';

const AcceptingOrdersToggle = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [isAcceptingOrders, setIsAcceptingOrders] = useState(true);

    useEffect(() => {
        fetch(`${apiBaseUrl}/get_accepting_orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${oauthToken}`
            },
            body: `api_key=${adminApiKey}`
        })
        .then(response => response.json())
        .then(data => setIsAcceptingOrders(data.accepting_orders))
        .catch(error => console.error('Error fetching accepting orders mode:', error));
    }, [apiBaseUrl, adminApiKey]);

    const handleToggleChange = () => {
        const newAcceptingOrdersState = !isAcceptingOrders;
        setIsAcceptingOrders(newAcceptingOrdersState);
        updateAcceptingOrdersMode(newAcceptingOrdersState);
        console.log(newAcceptingOrdersState)
    };

    const updateAcceptingOrdersMode = async (acceptingOrders) => {
        console.log(acceptingOrders)
        try {
            const response = await fetch(`${apiBaseUrl}/set_accepting_orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}&accepting_orders=${acceptingOrders}`
            });
            const data = await response.json();
            if (data.status !== 'success') {
                // Handle unsuccessful update
                console.error('Failed to update accepting orders mode');
            }
        } catch (error) {
            console.error('Error updating accepting orders mode:', error);
        }
    };

    // Refresh the button state every so often
    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${apiBaseUrl}/get_accepting_orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${oauthToken}`
                },
                body: `api_key=${adminApiKey}`
            })
            .then(response => response.json())
            .then(data => setIsAcceptingOrders(data.accepting_orders))
            .catch(error => console.error('Error fetching accepting orders mode:', error));
        }, 10000);
        return () => clearInterval(interval);
    }
    , []);

    return (
        <div>
            <h2>Accepting Orders</h2>
            <label>
                <input type="checkbox" checked={isAcceptingOrders} onChange={handleToggleChange} />
                Accepting Orders
            </label>
        </div>
    );
}

export default AcceptingOrdersToggle;