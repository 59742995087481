import React, { useState } from 'react';
import PriceManagement from './PriceManagement';
import OrderManagement from './OrderManagement';
import StatsView from './StatsView';
import Promos from './Promos';

const AdminPanel = ({ apiBaseUrl, adminApiKey, oauthToken }) => {
    const [activeTab, setActiveTab] = useState('orders');

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'prices':
                return <PriceManagement apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />;
            case 'orders':
                return <OrderManagement apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />;
            case 'stats':
                return <StatsView apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />;
            case 'promos':
                return <Promos apiBaseUrl={apiBaseUrl} adminApiKey={adminApiKey} oauthToken={oauthToken} />;
            default:
                return <div>Select a tab</div>;
        }
    };

    return (
        <div>
            <div className="tab-menu">
                <button onClick={() => setActiveTab('orders')}>Order Management</button>
                <button onClick={() => setActiveTab('prices')}>Price Management</button>
                <button onClick={() => setActiveTab('stats')}>Statistics</button>
                <button onClick={() => setActiveTab('promos')}>Promotions</button>
            </div>
            <div className="tab-content">
                {renderActiveTab()}
            </div>
        </div>
    );
};

export default AdminPanel;
